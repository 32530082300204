import React from 'react';
import styles from './index.module.less';
import Image from 'next/image';

const NameStarContent = ({ index = 5 }: { index?: number }) => {
  return (
    <div className={styles.content}>
      <div>
        {[1, 2, 3, 4, 5].map((item, itemIndex) => {
          const imageStr =
            itemIndex < index
              ? '/images/hairstyle/star-icon.png'
              : '/images/hairstyle/star-empty-icon.png';
          return (
            <Image
              key={itemIndex}
              src={imageStr}
              alt={'star'}
              width={18}
              height={18}
              style={{ marginRight: '5px' }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default NameStarContent;
